import { takeLatest, put, take, select } from "redux-saga/effects";
import ActionTypes from "./const";
import ActionTypesUsers from "src/components/users/const";
import {
  assign,
  fetchList,
  unassign,
  cellSpinnerStart,
  assignPeriod,
  assignPeriodSuccess,
  assignPeriodFailed,
  detachPeriodFailed,
  detachPeriodSuccess,
} from "./actions";
import { selectUser } from "src/components/users/actions";
import { IUser } from "src/components/users/types";
import requestGen from "src/utils/requestGen";
import { dateToStr } from "src/utils/dateUtil";
import { AppState } from "src/types";

export default function* () {
  // ASSIGN
  yield takeLatest(
    ActionTypes.ASSIGN,
    function* (action: ReturnType<typeof assign>) {
      console.log("Filter role", action.payload.role);

      yield put(selectUser(action.payload.role, action.payload.clubId));

      const assignCleaners = yield select(
        (state: AppState) => state.assignCleaners
      );
      const userAction = yield take(ActionTypesUsers.USER_SELECTED);
      const user: IUser = userAction.payload;

      if (!user) {
        return;
      }

      yield put(
        cellSpinnerStart(
          action.payload.role,
          action.payload.date,
          action.payload.listId
        )
      );

      if (action.payload.role === "cleaner") {
        yield requestGen({
          url: `/admin/api/schedule/assignCleaner/${action.payload.listId}`,
          method: "POST",
          data: {
            user: user.id,
            date: dateToStr(action.payload.date),
          },
        });
      }
      if (action.payload.role === "manager") {
        yield requestGen({
          url: `/admin/api/schedule/assignInspector/${action.payload.listId}`,
          method: "POST",
          data: {
            user: user.id,
            date: dateToStr(action.payload.date),
          },
        });
      }

      yield put(
        fetchList(
          {
            startDate: assignCleaners.periodFrom,
            endDate: assignCleaners.periodTo,
            clubId: assignCleaners.clubId,
          },
          true
        )
      );
    }
  );
  // ASSIGN PERIOD
  yield takeLatest(
    ActionTypes.ASSIGN_PERIOD_START,
    function* (action: ReturnType<typeof assignPeriod>) {
      const assignCleaners = yield select(
        (state: AppState) => state.assignCleaners
      );
      yield put(
        fetchList(
          {
            startDate: assignCleaners.periodFrom,
            endDate: assignCleaners.periodTo,
            clubId: assignCleaners.clubId,
          },
          true
        )
      );
      console.log("attach start");
      const res = yield requestGen({
        url: `/admin/api/schedule/assign/${action.payload.listId}`,
        method: "POST",
        data: action.payload,
      });
      if (res.err) {
        yield put(assignPeriodFailed());
      } else {
        yield put(
          fetchList(
            {
              startDate: assignCleaners.periodFrom,
              endDate: assignCleaners.periodTo,
              clubId: assignCleaners.clubId,
            },
            true
          )
        );
        yield put(assignPeriodSuccess());
      }
    }
  );

  // ASSIGN PERIOD
  yield takeLatest(
    ActionTypes.DETACH_PERIOD_START,
    function* (action: ReturnType<typeof assignPeriod>) {
      console.log("detach start");
      const assignCleaners = yield select(
        (state: AppState) => state.assignCleaners
      );

      const res = yield requestGen({
        url: `/admin/api/schedule/detach/${action.payload.listId}`,
        method: "POST",
        data: action.payload,
      });
      if (res.err) {
        yield put(detachPeriodFailed());
      } else {
        yield put(
          fetchList(
            {
              startDate: assignCleaners.periodFrom,
              endDate: assignCleaners.periodTo,
              clubId: assignCleaners.clubId,
            },
            true
          )
        );
        yield put(detachPeriodSuccess());
      }
    }
  );
  // UNASSIGN
  yield takeLatest(
    ActionTypes.UNASSIGN,
    function* (action: ReturnType<typeof unassign>) {
      const assignCleaners = yield select(
        (state: AppState) => state.assignCleaners
      );

      yield put(
        cellSpinnerStart(
          action.payload.role,
          action.payload.date,
          action.payload.listId
        )
      );

      if (action.payload.role === "cleaner") {
        yield requestGen({
          url: `/admin/api/schedule/detachCleaner/${action.payload.listId}`,
          method: "DELETE",
          data: {
            date: dateToStr(action.payload.date),
          },
        });
      }
      if (action.payload.role === "manager") {
        yield requestGen({
          url: `/admin/api/schedule/detachInspector/${action.payload.listId}`,
          method: "DELETE",
          data: {
            date: dateToStr(action.payload.date),
          },
        });
      }

      yield put(
        fetchList(
          {
            startDate: assignCleaners.periodFrom,
            endDate: assignCleaners.periodTo,
            clubId: assignCleaners.clubId,
          },
          true
        )
      );
    }
  );
}
