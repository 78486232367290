import React, { FC, ReactElement, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, PropTypes as MuiPropTypes } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import { Record } from "ra-core";
import { ReferenceInput, SelectInput } from "react-admin";

import { useCreateController } from "ra-core";
import {
  required,
  Button,
  ButtonProps,
  TextInput,
  ReferenceArrayInput,
} from "react-admin";

import IconContentAdd from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ModalForm from "src/components/ModalForm";
import { AutocompleteArrayInput } from "react-admin";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1.6),
  },
}));

interface Props {
  alignIcon?: "left" | "right";
  children?: ReactElement;
  classes?: object;
  className?: string;
  color?: MuiPropTypes.Color;
  disabled?: boolean;
  onCreate: (data: any) => void;
  label?: string;
  size?: "small" | "medium" | "large";
}

type ButtonProps = Props & MuiButtonProps;
const ClubGroupCreateButton: FC<EditButtonProps> = ({
  basePath = "",
  label = "Add document",
  record,
  icon = defaultIcon,
  onCreate,
  ...rest
}) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const me = JSON.parse(localStorage.getItem("thisUser"));

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  // const handleSubmit = e => {
  //   console.log("HandeSubmit")
  //   e.preventDefault();

  //   return false;
  // };
  const { save, saving } = useCreateController({
    resource: "club",
    basePath: "/club",
  });

  return (
    <Fragment>
      <Button
        disabled={rest?.disabled}
        onClick={handleClick}
        className={classes.button}
        label="ra.action.create"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
        style={{ zIndex: 1 }}
      >
        <DialogTitle>Create Group</DialogTitle>

        <ModalForm
          handleClose={handleCloseClick}
          save={(data: any) => {
            const newData = {
              ...data,
              users: data?.users ? [...data.users, me?.id] : [me?.id],
            };
            save(newData, "/club", {
              onSuccess: (data) => {
                setShowDialog(false);
                if (onCreate) {
                  onCreate(data);
                }
              },
            });
          }}
          record={{}}
          saving={saving}
          initialValues={{ isGroup: true }}
        >
          <ReferenceInput
            label="Parent Group"
            source="parentId"
            reference="club"
            filter={{ isGroup: true }}
            variant={"outlined"}
            fullWidth={true}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput
            source="name"
            label={"Group name"}
            validate={required()}
            variant={"outlined"}
            fullWidth={true}
          />
          <ReferenceArrayInput label={"Users"} source="users" reference="user">
            <AutocompleteArrayInput
              sort={{ field: "firstName", order: "ASC", forSelector: true }}
              variant={"outlined"}
              fullWidth={true}
            />
          </ReferenceArrayInput>
        </ModalForm>
      </Dialog>
    </Fragment>
  );
};

const defaultIcon = <AddIcon />;

interface Props {
  basePath?: string;
  record?: Record;
  icon?: ReactElement;
}

export type EditButtonProps = Props & ButtonProps & MuiButtonProps;

ClubGroupCreateButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
};

export default ClubGroupCreateButton;
